<template>
    <el-dialog
            :title=" '请选择发布截止日期' "
            :close-on-click-modal="false"
            width="30%"
            :visible.sync="visible">
        <el-form v-loading="formLoading" :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="50px">
            <el-form-item label="日期" prop="showEndTime" >
                <el-date-picker
                        v-model="dataForm.showEndTime"
                        type="date"
                        style="width:100%"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="formLoading || isOnSubmit">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import { publishInfo } from '@/api/hbi/report.js'
    export default {
        data () {
            return {
                visible: false,
                isOnSubmit: false,
                formLoading: false,
                dataForm: {
                    id:null,
                    showEndTime: '',
                    categoryId: ''
                },
                categoryIdList : [],
                dataRule: {
                    showEndTime: [{ required: true, message: '发布截止日期不能为空', trigger: 'blur' }]
                }
            }
        },
        created() {
        },
        methods: {
            init (id,categoryId) {
                this.visible = true
                this.isOnSubmit = false
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    //再次初始化
                    this.dataForm = {
                        showEndTime: ''
                    }
                    this.dataForm.id = id
                    this.dataForm.categoryId = categoryId
                })
            },
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.isOnSubmit = true
                        const data_ = {
                            'id': this.dataForm.id ,
                            'showEndTime': this.dataForm.showEndTime
                        }

                        publishInfo(data_).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 500
                                })
                                this.visible = false
                                this.$emit('refreshDataList',this.dataForm.categoryId)
                            } else {
                                this.$message.error(data.msg)
                            }
                        }).catch((err) => {
                            this.$message.error(err)
                        }).finally(()=>{
                            this.isOnSubmit = false
                        })
                    }
                })
            }
        }
    }
</script>
<style scoped>
</style>